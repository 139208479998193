import * as React from "react";
import styled from "styled-components";
const RITlogo = require("./assets/images/RITlogo.png");
const purpleLine = require("./assets/images/0157048d-5c0b-4d0d-b9c6-56617c027ec1.png");

const accomplishmentsData = [
  {
    title: "University GPA",
    value: "3.82/4.0",
    icon: purpleLine,
  },
  {
    title: "Licenses & certifications",
    items: [
      {
        name: "Neural Networks and Deep Learning",
        link: "https://coursera.org/verify/C3SKHTDPEPZH",
      },
      {
        name: "Improving Deep Neural Networks: Hyperparameter tuning, Regularization and Optimization",
        link: "https://coursera.org/verify/DR3EZFZHFUDC",
      },
      {
        name: "Structuring Machine Learning Projects",
        link: "https://coursera.org/verify/L4EENKMQ2NZG",
      },
      {
        name: "Sequences, Time Series and Prediction",
        link: "https://coursera.org/verify/NF8KR85Y36V2",
      },
    ],
    icon: purpleLine,
  },
  {
    title: "Related Coursework",
    items: [
      "Analysis of Algorithms",
      "Concepts of Parallel and Distributed Systems",
      "Concepts of Computer Systems",
      "Intro to Artificial Intelligence",
      "Intro to Computer Science Theory",
      "Intro to Computer Vision",
      "Machine Learning",
      "Principal of Data Management",
      "The Mechanics of Programming",
      "Principal of Data Mining",
      "Programming Language Concepts",
      "Intro to Software Engineering",
    ],
    icon: purpleLine,
  },
];

function MySchoolings() {
  return (
    <AccomplishmentsSection id="accomplishments-section">
      <AccomplishmentsContainer>
        <AccomplishmentsContent>
          <ProfileImageColumn>
            <ProfileImage src={RITlogo} alt="RIT Logo" loading="lazy" />
          </ProfileImageColumn>
          <AccomplishmentsColumn>
            <AccomplishmentsWrapper>
              <AccomplishmentsTitle>
                Accomplishments & Extracurriculars
              </AccomplishmentsTitle>
              <AccomplishmentsDescription>
                I'm studying Computer Science with a specialization in AI/ML and
                a minor in Astronomy at the Rochester Institute of Technology
                (RIT). Additionally, I enhance my knowledge by taking online
                courses and earning certifications.
              </AccomplishmentsDescription>
              {accomplishmentsData.map((item, index) => (
                <React.Fragment key={index}>
                  {item.value ? (
                    <AccomplishmentItem>
                      <AccomplishmentTitle>{item.title}</AccomplishmentTitle>
                      <AccomplishmentValue>{item.value}</AccomplishmentValue>
                    </AccomplishmentItem>
                  ) : (
                    <>
                      <AccomplishmentTitle>{item.title}</AccomplishmentTitle>
                      {item.items && (
                        <AccomplishmentList>
                          {item.items.map((course, i) =>
                            typeof course === "string" ? (
                              <AccomplishmentListItemText key={i}>
                                {course}
                              </AccomplishmentListItemText>
                            ) : (
                              <AccomplishmentListItem
                                key={i}
                                href={course.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {course.name}
                              </AccomplishmentListItem>
                            )
                          )}
                        </AccomplishmentList>
                      )}
                      <AccomplishmentIcon
                        src={item.icon}
                        alt={`${item.title} icon`}
                        loading="lazy"
                      />
                    </>
                  )}
                </React.Fragment>
              ))}
            </AccomplishmentsWrapper>
          </AccomplishmentsColumn>
        </AccomplishmentsContent>
      </AccomplishmentsContainer>
    </AccomplishmentsSection>
  );
}

export default MySchoolings;

const AccomplishmentsSection = styled.section`
  background-color: #121212;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const AccomplishmentsContainer = styled.div`
  padding-top: 24px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AccomplishmentsContent = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const ProfileImageColumn = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ProfileImage = styled.img`
  width: 50%;
  aspect-ratio: 0.93;
  object-position: center;
  margin: 0 auto;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const AccomplishmentsColumn = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const AccomplishmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #959595;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const AccomplishmentsTitle = styled.h2`
  color: #fff;
  font: 500 38px Poppins, sans-serif;
  text-transform: capitalize;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AccomplishmentsDescription = styled.p`
  margin: 30px 30px 0 0;
  font: 400 17px Poppins, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
  }
`;

const AccomplishmentItem = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  align-self: start;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const AccomplishmentTitle = styled.h3`
  font: 500 30px Poppins, sans-serif;
  text-transform: capitalize;
  flex-grow: 1;
  flex-basis: auto;
`;

const AccomplishmentValue = styled.span`
  margin: auto 0;
  font: 700 17px Poppins, sans-serif;
  text-transform: uppercase;
`;

const AccomplishmentIcon = styled.img`
  width: 100%;
  height: 4px;
  background-color: #d514ce;
  margin-top: 23px;
`;

const AccomplishmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const AccomplishmentListItem = styled.a`
  background-color: #1e1e1e;
  padding: 10px 15px;
  border-radius: 5px;
  font: 400 12px Poppins, sans-serif;
  text-transform: capitalize;
  color: #fff;
  text-decoration: none;

  &:hover {
    background-color: #3e3e3e;
  }
`;

const AccomplishmentListItemText = styled.div`
  background-color: #1e1e1e;
  padding: 10px 15px;
  border-radius: 5px;
  font: 400 12px Poppins, sans-serif;
  text-transform: capitalize;
  color: #fff;
`;
