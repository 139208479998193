import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import MyExperience from "./Experience";
import MyFooter from "./Footer";
import MySkills from "./Skills";
import MySchoolings from "./Schoolings";
import MyProjects from "./Projects";
import MyIntroduction from "./Introduction";
import { useNavigate } from "react-router-dom";
const MyLogo = require("./assets/images/QQLogo.png");
const gmailLink = "mailto:quymquan@gmail.com";

const App = () => {
  const [activeSection, setActiveSection] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
    if (window.innerWidth <= 991) {
      setIsMenuOpen(false); // Close menu on section click in mobile view
    }
  };

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <PageWrapper>
      <Header>
        {/* <Logo
          onClick={() => navigate("/2d-portfolio/")}
          src={MyLogo}
          alt="Logo"
        /> */}
        <Logo onClick={() => navigate("")} src={MyLogo} alt="Logo" />
        <MobileMenuButton onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </MobileMenuButton>
        <Nav $isMenuOpen={isMenuOpen}>
          <NavLink
            to="home-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "home-section"}
            onSetActive={handleSetActiveSection}
          >
            Home
          </NavLink>
          <NavLink
            to="about-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "about-section"}
            onSetActive={handleSetActiveSection}
          >
            About
          </NavLink>
          <NavLink
            to="skills-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "skills-section"}
            onSetActive={handleSetActiveSection}
          >
            Skills
          </NavLink>
          <NavLink
            to="accomplishments-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "accomplishments-section"}
            onSetActive={handleSetActiveSection}
          >
            Accomplishments
          </NavLink>
          <NavLink
            to="experience-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "experience-section"}
            onSetActive={handleSetActiveSection}
          >
            Experience
          </NavLink>
          <NavLink
            to="projects-section"
            smooth={true}
            duration={500}
            spy={true}
            $active={activeSection === "projects-section"}
            onSetActive={handleSetActiveSection}
          >
            Projects
          </NavLink>
          <a href={gmailLink}>
            <ContactButton>Contact Me</ContactButton>
          </a>
        </Nav>
      </Header>
      <MyIntroduction />
      <MySkills />
      <MySchoolings />
      <MyExperience />
      <MyProjects />
      <MyFooter />
    </PageWrapper>
  );
};

export default App;

const PageWrapper = styled.div`
  background-color: var(--blck, #211d25);
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 120px;
  margin: 0 auto;
  gap: 20px;
  font-weight: 900;
  text-align: justify;
  z-index: 10;
  background-color: var(--blck, #211d25);

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 15px 20px;
  }
`;
const Logo = styled.img`
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  color: var(--newwwwwwwwwwww, #959595);
  font-weight: 700;
  letter-spacing: 0.6px;
  margin: auto 0;

  @media (max-width: 991px) {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--blck, #211d25);
    text-align: center;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: ${(props) => (props.$isMenuOpen ? "1000px" : "0")};
  }
`;

const NavLink = styled(Link)`
  font-family: Lato, sans-serif;
  color: ${(props) => (props.$active ? "var(--purp, #d514ce)" : "inherit")};
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover::after {
    width: 100%;
    right: 0;
  }

  // &::after {
  //   background: none repeat scroll 0 0 transparent;
  //   bottom: 0;
  //   content: "";
  //   display: block;
  //   height: 1px;
  //   right: 0;
  //   position: absolute;
  //   background: linear-gradient(to left, #f9dd94, #d514ce 100%);
  //   transition: width 0.5s ease 0s;
  //   width: ${(props) => (props.$active ? "100%" : "0")};
  // }

  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    right: 0;
    position: absolute;
    background: linear-gradient(to left, #f9dd94, #d514ce 100%);
    transition: width 0.5s ease 0s, right 0.5s ease 0s;
    width: 0;
  }

  @media (max-width: 991px) {
    padding: 10px 0;
  }
`;

const ContactButton = styled.button`
  background-color: #d514ce;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 40px;
  font: 16px Lato, sans-serif;
  font-weight: 700;
  letter-spacing: 0.48px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 12px 20px;
    margin-top: 20px;
  }
`;

const MobileMenuButton = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 991px) {
    display: flex;
  }

  span {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
    transition: all 0.3s ease-in-out;
  }

  &.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
`;

///Original Code
// import * as React from "react";
// import { useState } from "react";
// import { Link } from "react-scroll";
// import styled from "styled-components";
// import MyExperience from "./Experience";
// import MyFooter from "./Footer";
// import MySkills from "./Skills";
// import MySchoolings from "./Schoolings";
// import MyProjects from "./Projects";
// import MyIntroduction from "./Introduction";

// const MyLogo = require("./assets/images/QQLogo.png");

// function App() {
//   const [activeSection, setActiveSection] = useState("");

//   const handleSetActiveSection = (section) => {
//     setActiveSection(section);
//   };
//   return (
//     <PageWrapper>
//       <Header>
//         <NavLink
//           to="home-section"
//           smooth={true}
//           duration={500}
//           spy={true}
//           active={activeSection === "home-section"}
//           onSetActive={handleSetActiveSection}
//         >
//           <Logo src={MyLogo}></Logo>
//         </NavLink>
//         <Nav>
//           <NavLink
//             to="home-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "home-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             Home
//           </NavLink>
//           <NavLink
//             to="about-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "about-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             About
//           </NavLink>
//           <NavLink
//             to="skills-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "skills-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             Skills
//           </NavLink>
//           <NavLink
//             to="accomplishments-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "accomplishments-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             Accomplishments
//           </NavLink>
//           <NavLink
//             to="experience-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "experience-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             Experience
//           </NavLink>
//           <NavLink
//             to="projects-section"
//             smooth={true}
//             duration={500}
//             spy={true}
//             active={activeSection === "projects-section"}
//             onSetActive={handleSetActiveSection}
//           >
//             Projects
//           </NavLink>
//         </Nav>
//         <ContactButton>Contact Me</ContactButton>
//       </Header>
//       <MyIntroduction />
//       <MySkills />
//       <MySchoolings />
//       <MyExperience />
//       <MyProjects />
//       <MyFooter />
//     </PageWrapper>
//   );
// }

// export default App;

// const PageWrapper = styled.div`
//   background-color: var(--blck, #211d25);
//   // padding: 20px 0 20px;
// `;

// const Header = styled.header`
//   position: sticky;
//   top: 0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   // max-width: 1543px;
//   // width: 100%;
//   padding: 15px 100px;
//   margin: 0 auto;
//   gap: 20px;
//   font-weight: 700;
//   text-align: justify;
//   z-index: 10;
//   background-color: var(--blck, #211d25);

//   @media (max-width: 991px) {
//     flex-wrap: wrap;
//     padding: 15px 20px;
//   }
// `;

// const Logo = styled.button`
//   background: none;
//   border: none;
//   padding: 0;
//   cursor: pointer;
//   background-image: url(${MyLogo});
//   background-size: contain; /* Modified */
//   background-repeat: no-repeat; /* Modified */
//   width: 60px;
//   height: 60px;
// `;

// const Nav = styled.nav`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   font-size: 20px;
//   color: var(--newwwwwwwwwwww, #959595);
//   font-weight: 500;
//   letter-spacing: 0.6px;
//   margin: auto 0;

//   @media (max-width: 991px) {
//     flex-wrap: wrap;
//   }
// `;

// const NavLink = styled(Link)`
//   font-family: Lato, sans-serif;
//   color: ${(props) => (props.$active ? "var(--purp, #d514ce)" : "inherit")};
//   cursor: pointer;
//   text-decoration: none;
//   position: relative;

//   &:hover::after {
//     width: 100%;
//     right: 0;
//   }

//   &::after {
//     background: none repeat scroll 0 0 transparent;
//     bottom: 0;
//     content: "";
//     display: block;
//     height: 1px;
//     right: 0;
//     position: absolute;
//     background: linear-gradient(to left, #f9dd94, #d514ce 100%);
//     transition: width 0.5s ease 0s, right 0.5s ease 0s;
//     width: 0;
//   }
// `;

// const ContactButton = styled.button`
//   background-color: #d514ce;
//   color: #fff;
//   border: none;
//   border-radius: 8px;
//   padding: 12px 40px;
//   font: 16px Lato, sans-serif;
//   font-weight: 700;
//   letter-spacing: 0.48px;
//   cursor: pointer;

//   @media (max-width: 991px) {
//     padding: 12px 20px;
//   }
// `;
