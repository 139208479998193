import * as React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const gmailLogo = require("./assets/images/mdi_instagram.png");
const linkedinLogo = require("./assets/images/linkedin_logo.png");
const githubLogo = require("./assets/images/github_logo.png");
const phoneLogo = require("./assets/images/phone_logo.png");
const MyLogo = require("./assets/images/QQLogo.png");

const gmailLink = "mailto:quymquan@gmail.com";
const linkedinLink = "https://www.linkedin.com/in/quan-quy-ba9869221/";
const githubLink = "https://github.com/qq1990";
const phoneLink = "tel:+16038587153";
const navItems = [
  { label: "Home", url: "home-section" },
  { label: "About me", url: "about-section" },
  { label: "Skills", url: "skills-section" },
  { label: "Accomplishments", url: "accomplishments-section" },
  { label: "Experience", url: "experience-section" },
];

const contactInfo = [
  {
    icon: gmailLogo,
    label: "quymquan@gmail.com",
    link: gmailLink,
  },
  {
    icon: linkedinLogo,
    label: "Quan Quy",
    link: linkedinLink,
  },
  { icon: githubLogo, label: "qq1990", link: githubLink },
  { icon: phoneLogo, label: "+1(603)858-7153", link: phoneLink },
];

function Header() {
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <HeaderContent>
        <Nav>
          {/* <Logo onClick={() => navigate("/2d-portfolio/")} src={MyLogo}></Logo> */}
          <Logo onClick={() => navigate("")} src={MyLogo}></Logo>
          <NavList>
            {navItems.map((item, index) => (
              <NavItem key={index}>
                <StyledLink to={item.url} smooth={true} duration={500}>
                  {item.label}
                </StyledLink>
              </NavItem>
            ))}
          </NavList>
        </Nav>
      </HeaderContent>
    </HeaderWrapper>
  );
}

function ContactInfo() {
  return (
    <ContactInfoWrapper>
      <ContactDetails>
        {contactInfo.map((item, index) => (
          <ContactItem key={index}>
            {item.icon ? (
              <ContactIcon>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <ContactImage src={item.icon} alt="" />
                </a>
              </ContactIcon>
            ) : undefined}
            <ContactLabel>{item.label}</ContactLabel>
          </ContactItem>
        ))}
      </ContactDetails>
    </ContactInfoWrapper>
  );
}

function MyFooter() {
  return (
    <PageWrapper>
      <Header />
      <Main>
        <ContactInfo />
      </Main>
    </PageWrapper>
  );
}

export default MyFooter;

const PageWrapper = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
  padding: 40px 60px 24px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Main = styled.main`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
`;

const HeaderWrapper = styled.header`
  align-items: center;
  align-self: stretch;
  display: flex;
  text-align: justify;
  justify-content: center;
  padding: 0 60px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  background-image: url(${MyLogo});
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
`;

const NavList = styled.ul`
  justify-content: center;
  display: flex;
  gap: 40px;
  font-size: 20px;
  color: var(--foundation-white-dark-hover, var(--newwwwwwwwwwww, #959595));
  font-weight: 500;
  letter-spacing: 0.6px;
  list-style-type: none;

  @media (max-width: 991px) {
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const NavItem = styled.li`
  font-family: Lato, sans-serif;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const ContactInfoWrapper = styled.section`
  display: flex;
  margin-top: 40px;
  gap: 45px;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const ContactItem = styled.div`
  display: flex;
  gap: 14px;
`;

const ContactIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ContactImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 40px;
  stroke-width: 0.7px;
  border-color: rgba(87, 87, 87, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 50%;
  height: 40px;
`;

const ContactLabel = styled.p`
  color: var(--foundation-white-dark-hover, var(--newwwwwwwwwwww, #959595));
  text-align: justify;
  letter-spacing: 0.6px;
  margin: auto 0;
  font: 700 20px Lato, sans-serif;
`;
