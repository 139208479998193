import * as React from "react";
import styled from "styled-components";
const profileImage2 = require("./assets/images/profile_nbg2.png");
// const resumeUrl =
//   "https://drive.google.com/uc?export=download&id=1xwg11yMcDQYgqF_Hio9S48d45aNcp9Pg";

const resumeUrl =
  "https://drive.google.com/uc?export=download&id=1n_HXFWW3xT9fzadVY0TqKaF7zqwJFSH0";

// const resume =
//   "https://drive.google.com/file/d/1xwg11yMcDQYgqF_Hio9S48d45aNcp9Pg/view?usp=sharing";

// const resume =
//   "https://drive.google.com/file/d/1G6LVfx19iDULs9uSr2ofVkYXPLnKxwll/view?usp=drive_link";

function AboutMe() {
  return (
    <AboutMeSection>
      <AboutMeTitle>About Me</AboutMeTitle>
      <AboutMeSubtitle>
        <span>Welcome to my website! </span>
      </AboutMeSubtitle>
      <AboutMeContent>
        <ProfileImage src={profileImage2} alt="Profile Image" />
        <AboutMeText>
          <AboutMeDescription>
            I'm Quan Quy, a Computer Science major with an Astronomy minor at
            Rochester Institute of Technology (RIT), New York. I have a genuine
            passion for programming, problem-solving, and the wonders of the
            universe. I strive to continuously learn and improve, and I am
            grateful for the opportunities I've had to explore these fields.
            Through my coursework, I've been recognized for my problem-solving
            skills and meticulous approach. Outside of academics, I actively
            participate in programming competitions, hackathons, and astronomy
            clubs, where I enjoy collaborating with others to create innovative
            solutions. With a solid foundation in computer science and
            proficiency in programming languages like Python, Java, and C++, I
            am eager to contribute and make a positive impact in the world of
            technology and scientific exploration.
          </AboutMeDescription>
          <AboutMeDescription>
            I'm currently looking for a Software Engineering/Development or Data
            Science New Grad/Co-op position, willing to relocate anywhere.
          </AboutMeDescription>
          <DownloadResumeLink href={resumeUrl} download>
            <DownloadIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f57a354419b9b9cbdd0d1c0d2a2cc6c5bc64abb359b6c85263508db1252bc4ea?apiKey=64874d91905343b0a6103f03c78f2eb8&"
              alt="Download icon"
            />
            <span>Download Resume</span>
          </DownloadResumeLink>
        </AboutMeText>
      </AboutMeContent>
    </AboutMeSection>
  );
}

export default AboutMe;

const AboutMeSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 0 80px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const AboutMeTitle = styled.h2`
  color: var(--Foundation-White-Light, #fefefe);
  letter-spacing: 1.2px;
  margin-left: 32px;
  font: 700 40px Lato, sans-serif;
`;

const AboutMeSubtitle = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  letter-spacing: 0.6px;
  text-transform: capitalize;
  margin: 12px 0 0 20px;
  font: 500 20px/160% Lato, sans-serif;

  a {
    text-decoration: underline;
    color: var(--newwwwwwwwwwww, #959595);
  }
`;

const AboutMeContent = styled.div`
  display: flex;
  gap: 20px;
  margin: 40px 0 0 33px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const ProfileImage = styled.img`
  aspect-ratio: 0.69;
  object-fit: cover;
  width: 20%;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutMeText = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutMeDescription = styled.p`
  color: var(--foundation-white-dark-hover, var(--newwwwwwwwwwww, #959595));
  font: 500 20px/38px Lato, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const DownloadResumeLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--purp, #d514ce);
  align-self: start;
  margin-top: 40px;
  gap: 10px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.54px;
  padding: 12px 30px;
  text-decoration: none;

  @media (max-width: 991px) {
    padding: 12px 20px;
  }
`;

const DownloadIcon = styled.img`
  width: 24px;
  height: 24px;
`;
